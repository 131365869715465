<template>
  <div>
    <div class="pages-title">
      <h2 class="regulations-page-title">{{ $t("regulations_page.title") }}</h2>
    </div>
    <div class="container-regulations">
      <div class="hint-title">{{ $t("regulations_page.title-two") }}</div>

      <ul class="navRegulation mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-fm-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-fm"
            type="button"
            role="tab"
            aria-controls="pills-fm"
            aria-selected="true"
          >
            {{ $t("regulations_page.nav1") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-october-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-october"
            type="button"
            role="tab"
            aria-conrols="pills-october"
            aria-selected="false"
          >
            {{ $t("regulations_page.nav2") }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-fm"
          role="tabpanel"
          aria-labelledby="pills-fm-tab"
          tabindex="0"
        >
          <RegulationNPA />
        </div>
        <div
          class="tab-pane fade"
          id="pills-october"
          role="tabpanel"
          aria-labelledby="pills-october-tab"
          tabindex="0"
        >
          <RegulationCriteria />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegulationCriteria from "./RegulationCriteria.vue";
import RegulationNPA from "./RegulationNPA.vue";

export default {
  components: {
    RegulationNPA,
    RegulationCriteria,
  },
};
</script>

<style>
.navRegulation {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 4px;
  padding: 0;
}
.navRegulation .nav-item .nav-link {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
}
.navRegulation .nav-item .nav-link.active {
  background-color: #0088e1;
  color: white;
}
.navRegulation .nav-item .nav-link:hover {
  background-color: #0088e1;
  color: white;
}
.list-group {
  margin: 0 0 60px 0;
}

.hint-title {
  padding: 20px;
  margin: 40px 0 30px 0;
  background: rgba(76, 154, 199, 0.15);
  border-radius: 5px;
  font-weight: 400;
}

.container-regulations {
  padding: 0 20%;
}
.fa-book-open-reader {
  color: rgb(172, 172, 172);
  font-size: 30px;
}
.list-group-item {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0 3%;
  padding: 10px 0;
}
.list-group-item a {
  text-decoration: none;
  color: #1f1f1f;
  font-weight: 500;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
}
.list-group-item:hover .fa-book-open-reader {
  color: #0088e1;
}
.list-group-item:hover a {
  color: #0088e1;
}

.regulations-page-title {
  position: relative;
  top: 20px;
  font-weight: bold;
  letter-spacing: 5px;
  color: white;
}

@media (min-width: 425px) and (max-width: 768px) {
  .fa-book-open-reader {
    font-size: 25px;
  }

  .regulations-page-title {
    font-size: 30px;
  }
  .container-regulations {
    padding: 0 13%;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .navRegulation .nav-item .nav-link {
    padding: 8px 16px;
  }
  .list-group-item a {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Ограничение до 2 строк */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .fa-book-open-reader {
    font-size: 22px;
  }
  .regulations-page-title {
    font-size: 25px;
  }
  .list-group-item {
    font-size: 16px;
  }
  .container-regulations {
    padding: 0 7%;
  }
}
@media (max-width: 375px) {
  .navRegulation {
    flex-direction: column;
    align-items: center;
  }
  .navRegulation .nav-item .nav-link {
    width: 80vw;
    padding: 10px 0;
  }
  .list-group-item a {
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Ограничение до 2 строк */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .fa-book-open-reader {
    font-size: 20px;
  }
  .regulations-page-title {
    font-size: 20px;
  }
  .list-group-item {
    font-size: 14px;
  }
  .container-regulations {
    padding: 0 6%;
  }
}
</style>

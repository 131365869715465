<template>
  <div id="app">
    <!-- <SnowFall/> -->
    <Header />
    <keep-alive>
    <router-view/>
    </keep-alive>
    <Footer />
</div>
</template>



<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
// import SnowFall from './components/SnowFall.vue';

export default {
  components: {
    // SnowFall,
    Footer,
    Header,
  }
}
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');
@import url('./assets/bootstrap/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
padding: 0;
margin: 0;
box-sizing: border-box;
font-family: 'Roboto', sans-serif;
}

.container-expand {
  padding: 0 15px;
}

.header-sign-in {
  background: #e8e8e8;
}

.header-sign-in .container-expand {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
}

.header-sign-in-link-container {
  display: flex;
  align-items: center;
}

.main-nav-links-mobile {
  display: none;
}

.header-sign-in-link {
  font-size: 14px;
  color: #1f1f1f;
  text-decoration: none;
  vertical-align: middle;
  padding-right: 35px;
  border-right: 1px solid #888888;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.header-sign-in-link:hover {
  color: #1f1f1f;
}

.header-sign-in-link i {
  vertical-align: middle;
  color: #888888;
  font-size: 18px;
  margin-right: 10px;
}

.header-sign-in-languages {
  display: flex;
  height: 100%;
}

.header-sign-in-lang-link {
  margin-left: 30px;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-sign-in-lang-link div {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}

.pages-title {
  background: url("../src/assets/title-bg.jpg") no-repeat center center;
    background-size: cover;
    padding: 3px 10px;
    margin: 0 !important;
    color: white

}

.kg div {
  background-image: url();
  background-position: -311px -325px;
}

.ru div {
  background-position: -385px -325px;
}

.header-sign-in-lang-link:hover {
  border-bottom: 2px solid #4c9ac7;
}

.header-sign-in-lang-link img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.main-nav .container-expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: relative;
  z-index: 20;
  background: #ffffff;
}

.main-nav-logo div {
  width: 265px;
  height: 80px;
  background: url('assets/logo-ok.png') no-repeat center center;
  background-size: contain;
}

.main-nav-links {
  display: flex;
  justify-content: space-around;
  width: 75%;
}

.main-nav-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.main-nav-links ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.main-nav-links ul li a {
  color: #1f1f1f;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.main-nav-links ul li a:hover {
  border-bottom: 2px solid #4c9ac7;
}

.main-nav-links-navbar-mobile {
  position: absolute;
  top: 60px;
  opacity: 0;
  visibility: hidden;
  left: 0;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #dcdcdc;
  z-index: -5;
  transition: 1s;
}
.main-nav-links-navbar-mobile ul {
  padding: 35px 0 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
}
.main-nav-links-navbar-mobile ul li {
  margin: 0;
  padding: 0 45px;
  list-style: none;
  width: 50%;
  margin-bottom: 40px;
}
.main-nav-links-navbar-mobile ul li a {
  color: #1f1f1f;
  text-decoration: none;
}
.main-nav-links-navbar-mobile ul li a:hover {
  border-bottom: 2px solid #4c9ac7;
  font-family: "Myriad Pro Bold", sans-serif;
}

.main-nav-mobile-languages {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.main-nav-mobile-languages .main-nav-mobile-link {
  padding: 5px 15px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.main-nav-mobile-languages .main-nav-mobile-link div {
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
}
.main-nav-mobile-languages .main-nav-mobile-link:hover {
  border-bottom: 2px solid #4c9ac7;
}
.main-nav-mobile-languages .kg {
  margin-right: 60px;
}
.main-nav-mobile-languages .kg div {
  background-position: -265px -325px;
}
.main-nav-mobile-languages .ru div {
  background-position: -339px -325px;
}

.toggle {
  opacity: 1;
  visibility: visible;
  top: 80px;
  transition: 1s;
}

.navBarBtn .mdi-close {
  display: none;
}

.target .mdi-menu {
  display: none;
}

.target .mdi-close {
  display: block;
}
@media screen and (max-width: 1600px) {
  .main-info-left {
    padding: 65px 85px 0;
  }

  .main-info-left h1 {
    line-height: 1.3;
    font-size: 52px;
  }

  .main-info-left p {
    font-size: 24px;
    line-height: 32px;
  }

  .main-info-right {
    padding: 55px 55px 65px;
  }

  .about-us-text {
    padding: 65px 0 70px;
    max-width: 770px;
  }

  .how-it-works .container-expand {
    padding: 75px 15px;
  }

  .how-it-works .container-expand .how-it-works-text {
    width: 66.6666%;
  }

  .how-it-works:before {
    left: calc(25% + 20px);
  }

  .how-it-works:after {
    right: calc(25% + 20px);
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    padding: 0 60px;
  }

  .write-us .container-expand .write-us-inner {
    width: 66.6666%;
  }

  .write-us .container-expand form {
    width: 50%;
  }

  .write-us .container-expand {
    padding: 90px 15px 35px;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    width: 50%;
  }

  .regulatory .container-expand .regulatory-items {
    padding: 0 40px;
  }

  .regulatory .container-expand .regulatory-link {
    margin-bottom: 0;
  }

  .footer-nav-container .container-expand .footer-nav {
    padding: 0 40px;
  }

  .footer-contacts .container-expand .footer-contacts-items .footer-contacts-item {
    padding: 0 60px 0;
  }

  .footer-bottom .container-expand .footer-social {
    width: 200px;
    margin: 0 60px;
  }

  .faq-info .faq-left {
    padding: 70px 30px 10px 15px;
  }

  .faq-info .faq-right .write-us-inner {
    width: 75%;
  }

  .faq-info .faq-right form .write-us-inputs {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .faq-info .faq-right form .write-us-inputs input {
    width: 100%;
    margin-bottom: 20px;
  }

  .faq-info .faq-right p {
    margin-bottom: 35px;
  }

  .docs-block .container-expand {
    padding: 40px 15px 55px;
  }

  .docs-block .container-expand .hint-title,
  .docs-block .container-expand .docs-items {
    width: 66.6666%;
  }

  .instructions-inner-block-container .instructions-inner-right {
    padding: 65px 90px 0;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text span {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-video {
    width: 470px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-paragraphs {
    margin-bottom: 30px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns {
    padding: 30px 0 40px;
  }

  .statistics-items {
    flex-wrap: wrap;
  }

  .statistics-items .statistics-item {
    width: 100%;
    padding: 20px 50px;
    border-bottom: 1px solid #ffffff;
  }

  .statistics-items .statistics-item:last-child {
    border-bottom: none;
  }

  .statistics-items .statistics-item .statistics-item-title {
    flex-direction: row;
    justify-content: space-between;
  }

  .statistics-items .statistics-item .statistics-item-title h5,
  .statistics-items .statistics-item .statistics-item-title span {
    margin-bottom: 0;
  }

  .statistics-items .statistics-item .statistics-item-text {
    flex-direction: row;
    padding: 20px 75px;
  }

  .statistics-items .statistics-item .statistics-item-text .statistics-item-text-item {
    justify-content: flex-start;
    width: 33.3333%;
  }

  .statistics-items .statistics-item .statistics-item-text .statistics-item-text-item p {
    margin-right: 15px;
  }

  .statistics-block .container-expand .hint-title {
    margin-bottom: 40px;
  }

  .map-space {
    height: 540px;
  }

  .map-text-title {
    padding: 25px 16.6666%;
  }

  .slider {
    height: 280px;
    padding-top: 265px;
  }

  .map-text-main-info-text .map-text-main-info-text-item {
    font-size: 16px;
  }

  .map-text-link {
    width: 270px;
  }

  .recovery-block .container-expand {
    padding: 50px 0 0;
  }

  .recovery-block .hint-title {
    margin-bottom: 40px;
  }

  .recovery-form-block button {
    width: 100%;
  }

  .login-block .recovery-input-container-first {
    margin-bottom: 20px;
  }

  .login-block .recovery-list a {
    width: 66.6666%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .reg-step-map-title {
    padding: 30px 0;
  }

  .reg-step-btn {
    padding: 40px 0 65px;
  }

  .reg-step-1-left {
    padding: 65px 45px;
  }

  .datepicker-container {
    width: 100%;
  }

  .reg-step-form-radio-item {
    margin-bottom: 30px;
  }

  .pass-serial,
  .pass-number {
    width: 40%;
  }

  .reg-step-2-dropzones .reg-step-form-item {
    width: 100%;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone {
    height: 290px;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone .dz-image {
    height: 255px;
  }

  .reg-step-2-dropzones .reg-step-form-item-text {
    margin-bottom: 0;
    width: 60%;
  }

  .reg-step-2-dropzones .reg-step-form-item .issuing-authority-container {
    width: 75% !important;
  }

  .reg-final-block {
    padding: 30px 0 35px;
  }

  .reg-final-like {
    width: calc(33.3333% + 60px);
    padding: 0 60px;
  }

  .reg-final-like-text h3 {
    width: calc(100% + 60px);
    margin-left: -30px;
  }

  .reg-final-personal-area {
    padding: 30px 30px 0;
  }

  .reg-final-personal-area > a {
    width: 100%;
  }

  .check-block-container .hint-title {
    width: 66.6666%;
    margin-bottom: 20px;
  }

  .check-block-item-bottom {
    width: 66.6666%;
  }

  .check-block-item-bottom .reg-step-form-item {
    width: 23%;
  }

  .check-block-item-bottom .reg-step-form-item-width {
    width: 48%;
  }

  .check-block-item-bottom button {
    width: 23%;
  }

  .check-results-username {
    padding: 40px 0;
  }

  .check-results-btn a {
    width: 25%;
  }

  .my-apps-left {
    width: calc(25% + 30px);
  }

  .my-applications-title {
    width: calc(75% - 30px);
  }

  .my-apps-right {
    width: calc(75% - 30px);
  }

  .my-applications-title {
    padding: 40px 30px;
  }

  .my-apps-card {
    padding: 20px 0;
  }

  .my-apps-right {
    padding: 30px 15px 30px 30px;
  }

  .my-apps-card {
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  .my-apps-card-text {
    width: 50%;
    padding: 0 20px;
  }

  .my-apps-card-main-status {
    width: 100%;
  }

  .my-apps-card-links {
    width: 33.3333%;
    margin: 0 auto;
  }

  .my-apps-card-main-status-link {
    margin-bottom: 20px;
  }

  .my-apps-right-details {
    padding: 40px 15px 40px 30px !important;
  }

  .my-apps-detail-tabs-content {
    padding: 40px 0 60px;
  }

  .my-apps-details-status-info {
    margin-bottom: 40px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 16.666%;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-img img {
    width: 100%;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-btn a {
    width: 60%;
  }

  .reg-step-5-item-img-details-2 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }
  .reg-step-5-item-img-details-2 img {
    width: 66.6666% !important;
    margin-bottom: 10px !important;
  }

  .my-apps-detail-tabs-content-details-3 .my-apps-details-3-school-name {
    padding: 18px 11.1111%;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info {
    padding: 25px 11.1111% 10px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item p {
    width: 60%;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-btn a {
    width: 33.3333%;
  }
  .my-apps-detail-tabs-content-details-3 .padding-left, .my-apps-detail-tabs-content-details-3 .padding-right {
    width: 11.1111%;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-table h4 {
    margin: 0 11.1111% 15px;
  }

  .my-apps-details-3-info {
    margin: 0 11.1111%;
  }

  .my-apps-changing-block .reg-step-1-form-container,
  .my-apps-changing-block .reg-step-2-btn,
  .my-apps-changing-block .my-apps-details-3-info {
    width: 55.5555% !important;
  }

  .my-app-add-step-1-block .reg-step-1-form-container {
    width: 55.5555%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    margin-right: 22.2222%;
    width: 22.2222%;
  }

  .my-app-add-step-1-block .reg-step-4-form-container {
    width: 100% !important;
    padding: 20px 22.5% 20px;
  }

  .reg-spep-2-btn-add-step-2 {
    padding: 40px 22.5%;
  }

  .reg-spep-2-btn-add-step-2 a {
    width: 48% !important;
    margin: 0 !important;
  }

  .my-app-add-step-final-block .reg-final-like {
    padding: 0;
  }
  .my-app-add-step-final-block .reg-final-like-text h3 {
    font-size: 18px;
  }
  .my-app-add-step-final-block .reg-final-like-text span {
    font-size: 24px;
  }
  .my-app-add-step-final-block .reg-final-personal-area {
    width: 55.5555%;
    padding: 30px 0 0;
  }

  .my-apps-right-data .reg-step-5-item-img-details-2 img {
    margin: 0 !important;
    width: 65% !important;
  }
  .my-apps-right-data .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 22.2222%;
  }

  .my-apps-right-data-change .reg-step-1-form-container {
    width: 55.5555%;
  }

  .dir-pend-check-title {
    width: 75%;
  }

  .dir-pend-check-links a {
    padding: 12px 20px;
  }

  .director-pending-checks-list .my-apps-right {
    padding: 35px;
  }

  .dit-pend-check-top {
    padding: 40px 40px 20px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 22.2222%;
  }

  .director-pending-checks-request .reg-step-btn,
  .director-pending-checks-request .dir-req-prev-next-btns {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .accept-modal .accept-modal-block {
    width: 41.6666%;
  }

  .file-loaded .file-loaded-block {
    width: 41.6666%;
  }

  .dir-school-main .dir-school-edit a {
    width: 60%;
  }
}
@media screen and (max-width: 1200px) {
  .header-sign-in, .main-nav-links {
    display: none;
  }

  .main-nav-links-mobile {
    display: flex;
  }

  .header-sign-in-link {
    font-size: 18px;
    color: #888888;
    border-right: none;
  }

  .main-nav-links-mobile i {
    font-size: 36px;
  }

  .main-nav-logo div {
    width: 190px;
    height: 60px;
  }

  .main-nav .container-expand {
    padding: 10px 15px;
  }

  #navBarBtn {
    background: transparent;
    border: none;
    color: #888888;
  }

  .main-info .container-expand {
    padding: 0;
  }

  .main-info-right {
    display: none;
  }

  .main-info-left {
    width: 100%;
    padding: 65px 65px 105px;
  }

  .main-info-left .main-info-left-map a i,
  .regulatory .container-expand .regulatory-items .regulatory-item a i {
    font-size: 24px;
  }

  .main-info-left p {
    font-size: 18px;
    margin: 0 0 40px;
    line-height: 24px;
  }

  .main-info-left h1 {
    text-align: center;
    width: 100%;
    padding: 0 80px;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  .main-info-left .main-info-left-map {
    height: 65px;
    line-height: 65px;
  }

  .about-us-text {
    max-width: none;
    width: 100%;
    padding: 55px 0 50px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 35px;
  }

  .about-us-text p {
    font-size: 16px;
    line-height: 21px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-1 {
    background-position: -282px -5px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-2 {
    background-position: -282px -135px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-3 {
    background-position: -482px -195px;
  }

  .how-it-works .container-expand .how-it-works-text p {
    margin-bottom: 30px;
    line-height: 24px;
  }

  .how-it-works:before, .how-it-works:after {
    top: 39%;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    padding: 0 15px;
  }

  .how-it-works .container-expand {
    padding: 45px 0 50px;
  }

  .how-it-works .container-expand .how-it-works-cards {
    margin-bottom: 45px;
  }

  .how-it-works .container-expand .how-it-works-about-link {
    margin-bottom: 0;
    width: 33.3333%;
  }

  .write-us .container-expand {
    padding: 50px 15px 50px;
  }

  .write-us .container-expand form {
    width: 100%;
  }

  .write-us .container-expand p {
    font-size: 16px;
  }

  .write-us .container-expand form button {
    margin-bottom: 0;
  }

  .regulatory .container-expand {
    padding: 50px 0 45px;
  }

  .regulatory .container-expand .regulatory-items {
    padding: 0;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    padding: 0 15px;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item a {
    padding-left: 35px;
  }

  .footer-nav-container .container-expand {
    padding: 35px 15px 20px;
  }

  .footer-nav-container .container-expand .footer-nav ul {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .footer-nav-container .container-expand .footer-nav {
    padding: 0;
  }

  .footer-nav-container .container-expand .footer-nav ul li {
    margin-bottom: 25px;
    padding: 0 40px;
    width: 33.3333%;
  }

  .main-nav-link-1 {
    order: 1;
  }

  .main-nav-link-2 {
    order: 4;
  }

  .main-nav-link-3 {
    order: 2;
  }

  .main-nav-link-4 {
    display: none;
  }

  .main-nav-link-4-B {
    display: inline-block;
    order: 5;
  }

  .main-nav-link-5 {
    order: 3;
  }

  .main-nav-link-6 {
    order: 6;
  }

  .footer-contacts .container-expand .footer-contacts-items .footer-contacts-item {
    padding: 0 40px;
    width: 50%;
  }

  .footer-bottom .container-expand {
    padding: 0;
    flex-wrap: wrap;
  }

  .footer-bottom .container-expand .footer-social {
    padding: 20px 16.6666%;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #888888;
  }

  .footer-bottom .container-expand .footer-social i {
    font-size: 48px;
  }

  .footer-bottom .container-expand span {
    padding: 20px 15px;
  }

  .footer-bottom .container-expand p {
    padding: 20px 15px;
  }

  .faq-title {
    padding: 35px 0;
  }

  .faq-title h2 {
    font-size: 24px;
  }

  .faq-info {
    flex-wrap: wrap;
  }

  .faq-info .faq-left {
    width: 100%;
    padding: 30px 16.6666% 55px;
  }

  .faq-info .faq-right {
    width: 100%;
  }

  .faq-info .faq-right {
    padding: 50px 0;
  }

  .faq-info .faq-right p {
    width: 100%;
  }

  .faq-info .faq-right form button {
    width: 33.3333%;
    margin: 0 auto;
  }

  .faq-info .faq-right form .write-us-inputs input {
    width: 48%;
  }

  .docs-block .container-expand {
    padding: 30px 15px 45px;
  }

  .docs-block .container-expand .docs-items .docs-item a i {
    font-size: 24px;
  }

  .docs-block .container-expand .docs-items .docs-item a {
    padding-left: 50px;
  }

  .docs-block .container-expand .hint-title {
    margin-bottom: 35px;
  }

  .instructions-block .container-expand {
    padding: 40px 50px 50px;
  }

  .instructions-block .container-expand .instructions-items {
    font-size: 16px;
  }

  .instructions-block .container-expand .instructions-items .instructions-item-links a i {
    font-size: 32px;
  }

  .instructions-block .container-expand .instructions-items .instructions-item-links a {
    margin-bottom: 30px;
  }

  .instructions-block .container-expand .instructions-items,
  .instructions-block .container-expand .hint-title {
    width: 100%;
  }

  .instructions-inner-block-container .instructions-inner-left {
    display: none;
  }

  .instructions-inner-block-container .instructions-inner-right {
    width: 100%;
    padding: 35px 16.6666% 0;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-video {
    width: 100%;
    margin: 0 auto 30px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-paragraphs {
    font-size: 16px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-text-img {
    justify-content: space-between;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-text-img .instructions-inner-right-img-container {
    width: 48%;
    min-width: 48%;
    margin-right: 0;
  }

  .instructions-inner-right-text-container {
    width: 48%;
    font-size: 16px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text {
    padding-bottom: 30px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns {
    padding: 30px 0;
  }

  .statistics-block .container-expand {
    padding: 40px 0 55px;
  }

  .statistics-block .container-expand .hint-title {
    margin: 0 15px 40px;
  }

  .statistics-block .container-expand .panel-title {
    padding-left: 15px;
  }

  .statistics-items .statistics-item {
    padding: 15px 40px;
  }

  .statistics-items .statistics-item .statistics-item-text {
    padding: 15px 70px 0;
  }

  .map-space {
    height: 850px;
  }

  .map-space .map-search {
    width: 66.6666%;
  }

  .map-text-title {
    padding: 20px 15px;
  }

  .map-text-title h3 {
    font-size: 18px;
  }

  .map-text-link {
    margin: 20px auto 20px;
    width: 33.3333%;
  }

  .map-text-statistics {
    padding: 0;
    margin-bottom: 40px;
  }

  .map-text-main-info {
    padding: 0 15px;
  }

  .slider {
    height: 260px;
    padding-top: 245px;
  }

  .map-text-main-info-text .map-text-main-info-text-item {
    margin-bottom: 20px;
  }

  .map-text-main-info-text .map-text-main-info-text-item:last-child {
    margin-bottom: 0;
  }

  .map-text-contacts {
    padding: 25px 15px;
  }

  .recovery-block .container-expand {
    padding: 40px 0 0;
  }

  .recovery-notice {
    padding: 25px 0;
  }

  .recovery-info,
  .recovery-notice p {
    width: 66.6666%;
  }

  .recovery-notice p {
    font-size: 16px;
  }

  .recovery-form-block {
    width: 100%;
  }

  .recovery-label {
    font-size: 16px;
  }

  .recovery-form-block button {
    margin-bottom: 25px;
  }

  .forgot-password {
    margin-bottom: 30px;
  }

  .login-block .recovery-list {
    width: 66.6666%;
    font-size: 16px;
  }

  .login-block .recovery-list > span,
  .login-block .recovery-list li {
    margin-bottom: 15px;
  }

  .login-block .recovery-list a {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .reg-step-1-left {
    display: none;
  }

  .reg-step-1-left {
    padding: 45px 0;
  }

  .reg-step-1-right-title span {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .reg-step-1-right-title h3 {
    font-size: 24px;
  }

  .reg-step-1-right-title {
    margin-bottom: 20px;
  }

  .reg-step-1-form-container {
    width: 66.6666%;
  }

  .reg-step-1-right .hint-title {
    margin-bottom: 15px;
  }

  .reg-step-form-item-double {
    flex-direction: column;
  }

  .select-1 {
    width: 100%;
  }

  .select-2 {
    width: 50%;
    margin-left: 0;
  }

  .reg-step-map-free h4 {
    font-size: 18px;
  }

  .reg-step-btn a {
    width: 33.3333%;
    margin-right: 16.6666%;
  }

  .reg-step-btn {
    padding: 35px 0 55px;
  }

  .dropzone {
    height: 245px;
  }

  .dz-image {
    height: 210px;
    width: 300px;
  }

  .reg-step-2-btn a {
    margin: 0;
    width: 48%;
  }

  .reg-step-2-btn {
    padding: 30px 16.6666%;
    display: flex;
    justify-content: space-between;
  }

  .reg-step-form-radio-item {
    margin-bottom: 25px;
  }

  .pass-serial, .pass-number {
    width: 48%;
    margin-right: 0;
  }

  .reg-step-form-passport-info {
    justify-content: space-between;
  }

  .reg-step-3-password-container {
    width: 100%;
    margin-bottom: 40px;
  }

  .reg-step-3-password-container-inner {
    width: 66.6666%;
  }

  .reg-step-4-form-container {
    padding-bottom: 20px;
  }

  .dropzone-step-4 {
    height: 245px;
  }
  .dropzone-step-4 .dz-image {
    height: 215px;
  }

  .reg-step-5-items .hint-title {
    margin-bottom: 30px;
  }

  .reg-step-5-item {
    padding: 0 16.6666% 35px;
  }

  .reg-step-5-items .hint-title {
    margin: 0 16.6666% 30px;
  }

  .reg-step-5-item-line {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .reg-step-5-item-line p,
  .reg-step-5-item-line span,
  .reg-step-5-item-img,
  .reg-step-5-item-btn a {
    width: 100%;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img {
    justify-content: space-between;
    display: flex;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img img {
    width: 48%;
    height: 100%;
  }

  .reg-final-like {
    width: 66.6666%;
    padding: 0;
  }

  .reg-final-like-text h3 {
    margin: 0;
    width: 100%;
    margin-bottom: 35px;
  }

  .reg-final-like-text p {
    margin-bottom: 15px;
  }

  .reg-final-personal-area {
    width: 66.6666%;
    padding: 30px 0 0;
  }

  .reg-final-personal-area p {
    margin-bottom: 10px;
  }

  .check-block-container {
    padding: 40px 0 50px;
  }

  .check-block-container .hint-title {
    padding: 10px;
  }

  .check-block-item-elements-1 {
    width: 66.6666%;
    flex-wrap: wrap;
  }

  .check-block-item-elements-1 .reg-step-form-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .check-block-item-elements-1 button {
    width: 100%;
  }

  .check-block-item-elements {
    flex-wrap: wrap;
  }

  .check-block-item-bottom .reg-step-form-item {
    width: 48%;
    margin-bottom: 25px;
  }

  .check-block-item-bottom button {
    width: 100%;
  }

  .check-results-block .faq-title h2 {
    letter-spacing: 6px;
  }

  .check-results-username {
    padding: 30px 0;
  }

  .check-results-username h3 {
    font-size: 24px;
  }

  .check-results-main-info h4 {
    font-size: 18px;
  }

  .check-results-main-info i {
    margin-bottom: 15px;
  }

  .check-results-main-info span {
    font-size: 16px;
  }

  .check-results-notice-icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .check-results-notice h4 {
    margin: 0 0 15px;
    font-size: 16px;
  }

  .check-results-notice p {
    font-size: 16px;
  }

  .check-results-child-info-item {
    width: 100%;
  }

  .check-results-child-info-item p {
    width: 50%;
  }

  .check-results-child-info {
    padding: 20px 16.6666% 10px;
  }

  .padding-left, .padding-right {
    display: none;
  }

  .check-results-table h4 {
    margin: 0 15px 15px;
  }

  .check-results-btn a {
    width: 33.3333%;
  }

  .my-applications-title {
    display: none;
  }

  .my-applications-title-hidden {
    display: block;
    width: 100%;
    padding: 20px 45px;
    margin-bottom: 10px;
  }
  .my-applications-title-hidden h2 {
    font-size: 24px;
  }

  .header-my-apps .main-nav .container-expand {
    padding: 10px 15px;
  }

  .main-nav-logo div {
    width: 190px;
  }

  .header-my-apps .main-nav-logo {
    margin: 0;
  }

  .my-apps-left {
    width: calc(33.3333% + 15px);
  }

  .my-apps-right {
    width: calc(76.6666% - 15px);
    padding: 0;
  }

  .my-apps-card {
    margin: 0 15px;
  }
  .my-apps-card:last-child {
    margin-bottom: 15px;
  }

  .my-apps-card-text {
    width: 100%;
  }

  .my-apps-card-links {
    width: 100%;
    padding: 0 15px;
  }

  .my-apps-right-details {
    padding: 0 !important;
  }

  .my-apps-detail-tabs-links a {
    width: 33.3333%;
  }

  .my-apps-detail-tabs-content {
    padding: 20px 0 35px;
  }

  .my-apps-details-status {
    margin-bottom: 15px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-line {
    padding: 0 40px;
  }

  .my-apps-details-date {
    padding: 15px 40px !important;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0 40px;
  }
  .my-apps-detail-tabs-content .reg-step-5-item-btn a {
    width: 100%;
  }

  .my-apps-details-status,
  .my-apps-details-status-info {
    font-size: 16px;
  }

  .my-apps-detail-tabs-content-details-3 .my-apps-details-3-school-name {
    padding: 18px 15px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info {
    padding: 25px 15px 10px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item p {
    width: 80%;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-btn {
    padding: 30px 15px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-btn a {
    width: 100%;
  }
  .my-apps-detail-tabs-content-details-3 .padding-left, .my-apps-detail-tabs-content-details-3 .padding-right {
    width: 15px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item {
    width: 50% !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-table h4 {
    margin: 0 15px 15px;
  }

  .my-apps-details-3-info {
    margin: 0 15px;
  }

  .my-apps-changing-block .reg-step-1-form-container,
  .my-apps-changing-block .reg-step-2-btn,
  .my-apps-changing-block .my-apps-details-3-info {
    width: 80% !important;
  }

  .my-app-add-step-1-block .reg-step-1-right {
    padding: 20px 0 0;
  }

  .my-app-add-step-1-block .reg-step-1-form-container {
    width: 80%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    margin-right: 10%;
    width: 40%;
  }

  .my-app-add-step-1-block .reg-step-4-form-container {
    padding: 20px 10% 20px;
  }

  .reg-spep-2-btn-add-step-2 {
    padding: 30px 10% !important;
  }

  .my-app-add-step-3-block .reg-step-5-item-img img {
    width: 75%;
  }

  .my-app-add-step-3-block .reg-step-5-item-line {
    padding: 0;
  }

  .my-app-add-step-final-block .reg-final-personal-area {
    width: 100%;
    padding: 20px 15px 0;
  }

  .reg-final-block {
    padding: 0 0 20px;
  }

  .my-apps-right-data .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 30px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0;
  }

  .my-apps-right-data-change .reg-step-1-form-container {
    width: calc(100% - 60px);
  }

  .director-pending-checks-list .my-apps-left {
    width: 33.3333%;
    min-height: 95vh;
  }

  .director-pending-checks-list .my-apps-right {
    width: 66.6666%;
    padding: 0;
  }

  .dir-pend-check-links a {
    padding: 5px 10px 5px 15px;
  }

  .dit-pend-check-top {
    padding: 30px 25px;
  }

  .dir-pend-nav .dir-pend-search-input {
    width: 70%;
    margin: 0;
  }

  .dir-pend-nav .dir-pend-search-select {
    width: 25%;
    margin: 0;
  }

  .dir-page-links-inner {
    width: 85%;
  }

  .dir-pend-check-req-title h3 {
    font-size: 24px;
    padding: 0 25px;
    margin-bottom: 15px;
  }

  .director-pending-checks-request .my-apps-detail-tabs-links a {
    width: 23%;
  }

  .director-pending-checks-request .my-apps-detail-tabs-content .reg-step-5-item-line {
    padding: 0 25px;
  }

  .director-pending-checks-request .reg-step-btn, .director-pending-checks-request .dir-req-prev-next-btns {
    padding: 25px;
  }

  .accept-modal .accept-modal-block {
    width: 66.6666%;
  }

  .accept-modal-block-accepted {
    width: 58.3333% !important;
  }

  .file-loaded .file-loaded-block {
    width: 66.6666%;
  }

  .dir-pend-order-add-text,
  .dir-pend-order-add-buttons {
    padding: 0 15px !important;
  }

  .dir-rej-req-reasons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .map-text-main-info {
    flex-wrap: wrap;
  }

  .map-text-main-info-slider {
    width: 100%;
  }

  .map-text-main-info-text {
    width: 100%;
  }

  .dir-school-main .dir-pend-check-block {
    padding: 20px 15px;
  }

  .dir-school-main .map-text-contacts .map-text-contacts-phone {
    justify-content: flex-start;
  }

  .dir-school-main .map-text-contacts .map-text-contacts-phone b {
    margin-right: 10px;
  }

  .dir-school-main .dir-school-edit a {
    width: 100%;
  }

  .map-text-main-info {
    margin-bottom: 20px;
  }

  .slider input[name='slide_switch'] ~ img {
    transform: scale(1);
  }

  .dir-school-main .reg-step-2-btn {
    width: 100%;
    padding: 30px 0;
  }

  .reg-step-1-form-container {
    width: 100%;
  }
}
@media (min-width:425px) and (max-width: 768px){
  .pages-title {
    padding: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .header-sign-in-link span {
    display: none;
  }

  .header-sign-in-link {
    padding-right: 20px;
  }

  .header-sign-in-link i {
    margin-right: 0;
  }

  .main-nav-links-navbar-mobile ul li {
    width: 100%;
  }

  .main-info-left {
    padding: 40px 0 100px;
  }

  .main-info-left-links {
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .main-info-left .apply {
    margin-right: 0;
  }

  .main-info-left .main-info-link {
    width: 48%;
  }

  .main-info-left h1 {
    padding: 0 30px;
    font-size: 48px;
  }

  .main-info-left p {
    padding: 0 30px;
  }

  .about-us-text {
    padding: 45px 0 50px;
  }

  .how-it-works .container-expand .how-it-works-text {
    width: 100%;
    padding: 0 15px;
  }

  .how-it-works .container-expand .how-it-works-text p {
    font-size: 16px;
  }

  .how-it-works .container-expand .how-it-works-cards {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    margin: 0 10px 0 0;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-1 {
    background-position: -5px -135px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-2 {
    background-position: -5px -265px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-3 {
    background-position: -412px -195px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
    text-align: left;
  }

  .how-it-works:before {
    width: 1px;
    height: 30px;
    left: 45px;
    top: 39%;
    background: url('assets/dot.png') repeat-y;
  }

  .how-it-works:after {
    width: 1px;
    height: 30px;
    left: 45px;
    top: 56%;
    background: url('assets/dot.png') repeat-y;
  }

  .how-it-works .container-expand .how-it-works-about-link {
    width: 290px;
  }

  .write-us .container-expand .write-us-inner {
    width: 100%;
  }

  .write-us .container-expand form textarea {
    margin-bottom: 20px;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    width: 100%;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    margin-bottom: 20px;
  }

  .footer-nav-container .container-expand .footer-nav ul li {
    width: 50%;
    padding: 0 30px;
  }

  .main-nav-link-4-B {
    order: 2;
  }

  .main-nav-link-2 {
    order: 3;
  }

  .main-nav-link-5 {
    order: 4;
  }

  .main-nav-link-3 {
    order: 5;
  }

  .footer-contacts .container-expand .footer-contacts-items .footer-contacts-item {
    width: 100%;
    padding: 0 30px;
  }

  .footer-bottom .container-expand .footer-social i {
    font-size: 36px;
  }

  .footer-bottom .container-expand span {
    font-size: 12px;
  }

  .footer-bottom .container-expand p {
    font-size: 12px;
  }

  .faq-title {
    padding: 30px 0;
  }

  .faq-info .faq-left {
    padding: 20px 15px 30px;
  }

  .hint-title {
    margin: 0 0 30px;
    padding: 10px;
  }

  .panel-body {
    padding: 10px;
  }

  .faq-info .faq-right {
    padding: 45px 15px;
  }

  .faq-info .faq-right .write-us-inner {
    width: 100%;
  }

  .faq-info .faq-right form button {
    width: 50%;
  }

  .docs-block .container-expand {
    padding: 20px 15px 35px;
  }

  .docs-block .container-expand .hint-title,
  .docs-block .container-expand .docs-items {
    width: 100%;
  }

  .docs-block .container-expand .docs-items .docs-item a {
    padding-left: 30px;
  }

  .docs-block .container-expand .docs-items .docs-item {
    margin-bottom: 20px;
  }

  .instructions-block .container-expand .instructions-items .instructions-item-links a {
    width: 100%;
  }

  .instructions-block .container-expand .instructions-items .instructions-item-links {
    padding: 0 0 0 20px;
  }

  .instructions-block .container-expand {
    padding: 20px 15px 15px;
  }

  .instructions-block .container-expand .instructions-items h5 {
    margin: 25px 0;
  }

  .instructions-inner-block-container .instructions-inner-right {
    padding: 30px 15px 0;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text h3 {
    font-size: 24px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-video {
    margin: 0 0 30px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns .all-instructions {
    width: 165px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns {
    padding: 35px 0 40px;
  }

  .statistics-block .container-expand {
    padding: 20px 0 30px;
  }

  .statistics-items .statistics-item .statistics-item-text {
    flex-direction: column;
    padding: 15px 0 0;
  }

  .statistics-items .statistics-item .statistics-item-text .statistics-item-text-item {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .statistics-items .statistics-item .statistics-item-text .statistics-item-text-item:last-child {
    margin-bottom: 0;
  }

  .map-space {
    height: 640px;
  }

  .map-space .map-search {
    width: calc(100% - 30px);
  }

  .map-text-title {
    flex-wrap: wrap;
  }

  .map-text-title h3 {
    width: 100%;
  }

  .map-text-link {
    width: 50%;
    margin: 30px auto;
  }

  .map-text-main-info {
    flex-wrap: wrap;
  }

  .map-text-main-info-slider,
  .map-text-main-info-text {
    width: 100%;
  }

  .map-text-main-info-slider {
    margin-bottom: 160px;
    border-bottom: 1px solid #d0d0d0;
  }

  .slider {
    height: 335px;
    padding-top: 320px;
  }

  .map-text-contacts {
    flex-wrap: wrap;
  }

  .map-text-contacts span {
    width: 100%;
  }

  .map-text-contacts .map-text-contacts-phone {
    flex-direction: row;
  }

  .map-text-contacts .map-text-contacts-phone b {
    margin-right: 25px;
  }

  .map-text-contacts span {
    margin-bottom: 20px;
  }

  .recovery-block .container-expand {
    padding: 30px 0 0;
  }

  .recovery-block .hint-title {
    margin-bottom: 30px;
  }

  .recovery-info, .recovery-notice p {
    width: 100%;
    padding: 0 15px;
  }

  .login-block .recovery-list {
    width: 100%;
    padding: 0 15px;
  }

  .login-block .recovery-list > span, .login-block .recovery-list li {
    margin-bottom: 10px;
  }

  .login-block .recovery-input-container-first {
    margin-bottom: 15px;
  }

  .reg-step-1-right {
    padding: 30px 0 0;
  }

  .reg-step-1-form-container {
    width: 100%;
    padding: 0 15px;
  }

  .reg-step-1-right-title span {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .reg-step-1-right-title h3 {
    font-size: 18px;
  }

  .reg-step-map-title {
    padding: 20px 0;
  }

  .reg-step-map-free h4 {
    font-size: 24px;
  }

  .reg-step-map-items {
    height: 170px;
  }

  .reg-step-btn {
    padding: 30px 0;
  }

  .reg-step-btn a {
    width: 50%;
    margin-right: 15px;
  }

  .dropzone {
    height: 330px;
  }

  .dz-image {
    height: 300px;
    width: 420px;
  }

  .reg-step-2-btn a {
    margin: 0 !important;
    width: 48%;
  }

  .reg-step-2-btn {
    padding: 30px 15px;
  }

  .reg-step-3-password-container-inner {
    width: 100%;
  }

  .reg-step-3-password-container {
    padding: 20px 15px 45px;
    margin-bottom: 30px;
  }

  .dropzone-step-4 {
    height: 235px;
  }
  .dropzone-step-4 .dz-image {
    height: 205px;
  }

  .reg-step-5-items .hint-title {
    margin: 0 15px 30px;
  }

  .reg-step-5-item {
    padding: 0 15px 35px;
  }

  .reg-step-5-item-line {
    padding: 0 30px;
    margin-bottom: 10px;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img {
    flex-wrap: wrap;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img img {
    width: 100%;
  }

  .reg-step-5-item-phones {
    width: 100%;
  }

  .reg-final-like-img {
    width: 105px;
    height: 85px;
  }

  .reg-final-like {
    width: 100%;
    padding: 0 15px;
  }

  .reg-final-like-text span {
    margin-bottom: 25px;
  }

  .reg-final-personal-area {
    padding: 30px 15px 0;
    width: 100%;
  }

  .reg-final-like-text h3,
  .reg-final-like-text span {
    font-size: 24px;
  }

  .check-block-container {
    padding: 30px 0 35px;
  }

  .check-block-container .hint-title {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }

  .check-block-item-elements-1 {
    width: calc(100% - 30px);
    margin-bottom: 30px;
  }

  .check-block-item-bottom {
    width: calc(100% - 30px);
    padding-top: 30px;
  }

  .check-results-username {
    padding: 25px 0;
  }

  .check-results-main-info {
    padding: 15px 0 25px;
  }

  .check-results-main-info i {
    margin-bottom: 10px;
  }

  .check-results-notice {
    padding: 25px 15px 30px;
  }

  .check-results-child-info {
    padding: 20px 15px 10px;
  }

  .check-results-btn {
    padding: 30px 15px;
  }

  .check-results-btn a {
    width: 100%;
  }

  .my-apps-block {
    flex-wrap: wrap;
  }

  .my-apps-left {
    width: 100%;
  }

  .my-apps-right {
    width: 100%;
  }

  .my-apps-left {
    min-height: auto;
    padding: 15px;
  }

  .my-apps-left-links {
    justify-content: space-between;
  }

  .my-apps-left-links a {
    width: 49%;
    padding: 10px 15px;
    white-space: nowrap;
  }

  .my-applications-title-hidden {
    padding: 20px 15px;
    margin: 0;
  }

  .my-applications-title-hidden h2 {
    font-size: 18px;
  }

  .my-apps-card,
  .my-apps-card:last-child {
    margin: 0;
  }

  .my-applications-title-hidden-details {
    display: flex;
  }
  .my-applications-title-hidden-details h2 {
    width: 50%;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-line,
  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0 15px;
  }

  .my-apps-details-date {
    padding: 10px 15px !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item {
    width: 100% !important;
    padding: 0 20%;
  }

  .my-apps-changing-block {
    padding: 20px 0 30px;
  }

  .my-apps-changing-block .reg-step-1-form-container,
  .my-apps-changing-block .reg-step-2-btn,
  .my-apps-changing-block .my-apps-details-3-info {
    width: 100% !important;
  }

  .my-apps-changing-block .reg-step-2-btn {
    padding: 10px 15px 30px;
  }

  .my-apps-changing-block .my-apps-details-3-info {
    width: calc(100% - 30px) !important;
    margin: 0 15px;
  }

  .my-app-add-step-1-block .reg-step-1-form-container {
    width: 100%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    margin-right: 15px;
  }

  .my-app-add-step-1-block .reg-step-4-form-container {
    padding: 20px 15px;
  }

  .reg-spep-2-btn-add-step-2 {
    padding: 20px 15px !important;
  }

  .my-app-add-step-final-block .reg-final-like {
    padding: 0 15%;
  }

  .my-apps-right-data .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 15px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-line, .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0;
  }

  .my-apps-right-data-change .reg-step-1-form-container {
    width: 100%;
  }

  .my-apps-right-data-change .reg-step-2-btn {
    padding: 30px 0;
  }

  .director-pending-checks-list .my-apps-left {
    width: 100%;
    min-height: auto;
  }

  .director-pending-checks-list .my-apps-right {
    width: 100%;
  }

  .director-pending-checks-list .my-apps-left {
    padding: 10px 0 0;
  }

  .dir-pend-check-links a span {
    display: inline-block;
  }

  .director-pending-checks-list .my-apps-right .my-applications-title {
    padding: 15px 0;
  }

  .dit-pend-check-top {
    padding: 15px;
  }

  .dir-page-links-inner {
    margin-bottom: 20px;
  }

  .dir-pend-check-req-title h3 {
    padding: 15px;
    margin: 0;
  }

  .director-pending-checks-request .my-apps-detail-tabs-content .reg-step-5-item-line {
    padding: 0 15px;
  }

  .director-pending-checks-request .reg-step-btn, .director-pending-checks-request .dir-req-prev-next-btns {
    padding: 15px;
  }

  .accept-modal .accept-modal-block {
    width: calc(100% - 30px);
    padding: 40px;
  }

  .accept-modal-block-accepted {
    width: calc(100% - 30px) !important;
  }

  .file-loaded .file-loaded-block {
    width: calc(100% - 30px);
  }

  .map-text-main-info-slider {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 479px) {
  .main-nav-logo div {
    width: 140px;
    height: 40px;
  }

  .main-nav-links-mobile i, .main-nav-links-mobile i {
    font-size: 24px;
  }

  .header-sign-in-link {
    padding-right: 10px;
  }

  .main-info-left {
    padding: 30px 0 90px;
  }

  .main-info-left h1 {
    font-size: 32px;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .main-info-left p {
    font-size: 16px;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .main-info-left-links {
    flex-wrap: wrap;
  }

  .main-info-left .main-info-link {
    width: 100%;
  }

  .apply {
    margin-bottom: 20px;
  }

  .main-nav-links-navbar-mobile {
    top: 45px;
  }

  .toggle {
    top: 60px;
  }

  .main-nav-links-navbar-mobile ul li {
    padding: 0 0 0 40px;
  }

  .main-nav-mobile-languages {
    padding: 15px 0 5px;
  }

  .about-us-text, .how-it-works .container-expand {
    padding: 40px 0 40px;
  }

  .write-us .container-expand,
  .regulatory .container-expand {
    padding: 40px 15px 40px;
  }

  .how-it-works:before {
    height: 80px;
  }

  .how-it-works:after {
    height: 105px;
  }

  .write-us .container-expand form .write-us-inputs {
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  .write-us .container-expand form .write-us-inputs input {
    width: 100%;
    margin-bottom: 20px;
  }

  .write-us .container-expand form button {
    width: 100%;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    padding: 0;
  }

  .regulatory .container-expand .regulatory-link {
    width: 100%;
  }

  .footer-nav-container .container-expand .footer-nav ul li {
    width: 100%;
  }

  .footer-nav-container .container-expand .footer-nav ul li a {
    font-size: 16px;
  }

  .main-nav-link-2 {
    order: 2;
  }

  .main-nav-link-3 {
    order: 3;
  }

  .main-nav-link-4-B {
    order: 4;
  }

  .main-nav-link-5 {
    order: 5;
  }

  .footer-bottom .container-expand .footer-social i {
    font-size: 24px;
  }

  .footer-bottom .container-expand span, .footer-bottom .container-expand p {
    padding: 10px 0 0;
    text-align: center;
    margin: 0 auto;
  }

  .footer-bottom .container-expand p {
    margin-bottom: 10px;
  }

  .footer-logo-container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .footer-logo-container span {
    margin: 0 !important;
    padding: 0 !important;
  }

  .faq-title {
    padding: 15px 0;
  }

  .faq-title h2 {
    font-size: 18px;
  }

  .hint-title {
    margin-bottom: 15px;
  }

  .faq-info .faq-right form .write-us-inputs input {
    width: 100%;
  }

  .faq-info .faq-right form button {
    width: 100%;
  }

  .docs-block .container-expand {
    padding: 20px 15px 30px;
  }

  .instructions-block .container-expand .instructions-items h5 {
    margin: 20px 0;
  }

  .instructions-block .container-expand .instructions-items .instructions-item-links {
    padding: 0;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-video {
    margin-bottom: 20px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-text-img {
    flex-wrap: wrap;
  }

  .instructions-inner-right-text-container {
    width: 100%;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-text .instructions-inner-right-text-img .instructions-inner-right-img-container {
    width: 180px;
    min-width: 180px;
    margin: 0 auto 25px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns {
    padding: 25px 0;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns .prev, .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns .next {
    width: 55px;
  }

  .instructions-inner-block-container .instructions-inner-right .instructions-inner-right-pages-btns .all-instructions {
    width: 150px;
  }

  .statistics-items .statistics-item {
    padding: 20px;
  }

  .map-space {
    height: 380px;
  }

  .map-space .map-search {
    top: 20px;
  }

  .map-text-title h3,
  .map-text-title span {
    font-size: 16px;
  }

  .map-text-status {
    padding: 10px 0;
  }

  .map-text-link {
    margin: 10px auto;
    width: calc(100% - 30px);
  }

  .map-text-statistics {
    margin-bottom: 30px;
  }

  .slider {
    height: 215px;
    padding-top: 200px;
  }

  .slider label {
    height: 75px;
  }

  .map-text-main-info-slider {
    margin-bottom: 110px;
  }

  .map-text-contacts .map-text-contacts-phone {
    flex-direction: column;
  }

  .recovery-block .container-expand {
    padding: 25px 0 0;
  }

  .recovery-block .hint-title {
    margin-bottom: 20px;
  }

  .recovery-input-container input {
    font-size: 24px;
    text-overflow: ellipsis;
  }

  .login-block .recovery-notice p {
    margin-bottom: 10px;
  }

  .reg-step-map-title {
    border-top: none;
    padding: 0 0 20px;
  }

  .reg-step-btn {
    padding: 20px 0;
  }

  .dropzone {
    height: 220px;
  }

  .dz-image {
    height: 190px;
    width: 260px;
  }

  .issuing-authority-container {
    width: 75%;
  }

  .reg-step-2-btn {
    padding: 30px 15px;
  }

  .reg-step-form-passport-info {
    flex-wrap: wrap;
  }

  .pass-serial, .pass-number {
    width: 60%;
  }

  .pass-serial {
    margin-bottom: 20px;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone {
    height: 180px;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone .dz-image {
    height: 160px;
  }

  .reg-step-2-dropzones .reg-step-form-item-text {
    width: 100%;
  }

  .checkbox-container label {
    display: inline-block;
  }

  .dropzone-step-4 {
    height: 155px;
  }
  .dropzone-step-4 .dz-image {
    height: 130px;
  }

  .reg-step-5-item-line {
    padding: 0;
  }

  .reg-step-5-item-img img {
    margin-bottom: 5px;
  }

  .reg-final-like-text h3 {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .check-block-item-bottom .reg-step-form-item {
    width: 100%;
  }

  .check-results-username {
    padding: 20px 0;
  }

  .check-results-main-info i {
    font-size: 32px;
  }

  .check-results-notice-btn-mobile {
    display: block;
  }

  .check-results-child-info-item span {
    width: 50%;
  }

  .check-results-table tbody td span {
    font-size: 14px;
  }

  .check-results-table thead tr h5 {
    font-size: 14px;
  }

  .my-apps-left-links a {
    width: 100%;
  }

  .my-apps-card-text-item span,
  .my-apps-card-text-item p {
    white-space: normal;
  }

  .my-apps-card-text-item p {
    min-width: 50%;
  }

  .my-apps-card-main-status-inner {
    padding: 15px 45px;
  }

  .my-applications-title-hidden-details {
    flex-wrap: wrap;
  }
  .my-applications-title-hidden-details h2 {
    width: 100%;
  }

  .my-apps-details-status-info {
    margin: 0;
  }

  .reg-step-5-item-img-details-2 img {
    width: 100% !important;
  }

  .reg-step-5-item-img-details-2 {
    padding: 0 !important;
    background: transparent !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item {
    padding: 0 !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-table h4 {
    width: 60%;
  }

  .check-results-child-info-item span {
    width: 20%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    width: 50%;
  }

  .my-app-add-step-1-block .recovery-label {
    white-space: normal;
  }

  .my-app-add-step-3-block .reg-step-5-item-img img {
    width: 100%;
  }

  .my-app-add-step-final-block .reg-final-like {
    padding: 0 10%;
  }

  .my-apps-right-data .reg-step-5-item-img-details-2 img {
    width: 100% !important;
    margin-bottom: 5px !important;
  }

  .my-apps-right-data-change .reg-step-2-btn {
    padding: 15px 0;
  }

  .dir-pend-check-links .dir-link-active {
    margin-right: 5px;
  }

  .dir-pend-nav .dir-pend-search-input {
    width: 60%;
  }

  .dir-pend-nav .dir-pend-search-select {
    width: 35%;
  }

  .dir-page-links-inner {
    width: 90%;
  }

  .dir-pend-check-req-title h3 {
    font-size: 18px;
  }

  .director-pending-checks-request .my-apps-detail-tabs-links a {
    width: 25%;
    font-size: 14px;
  }

  .director-pending-checks-request .dir-req-prev-next-btns a {
    width: 50%;
  }

  .director-pending-checks-request .dir-req-prev-next-btns .prev,
  .director-pending-checks-request .dir-req-prev-next-btns .next {
    width: 25%;
  }

  .accept-modal .accept-modal-block {
    padding: 30px;
  }

  .file-loaded .file-loaded-block {
    padding: 30px;
  }

  .dir-school-main .slider {
    height: 215px;
    padding-top: 200px;
  }

  .dir-school-main .slider label {
    height: 70px;
  }

  .map-text-main-info-slider {
    margin-bottom: 90px;
  }

  .map-text-contacts .map-text-contacts-phone {
    flex-direction: column !important;
  }

  .dir-school-main .dropzone > div {
    max-width: 33.3333%;
  }
}


@media  (max-width: 1024px) {
    table {
      border: 0;
    }
    table thead {
      display: none;
    }
    table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid transparent;
    }
    table td {
      display: block;
      text-align: right;
      font-size: 13px;
      border: 2px solid transparent;
    }
    table td:last-child {
      border-bottom: 2px solid transparent;
    }
    table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
    }
    .books-total {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 8px;
        /* margin-right: 5px; */
        border-radius: 0px;
        background: none;
    }
    .my-column {
        display: flex;
        flex-direction: column;
    }
  }
  @media (min-width: 375px) and (max-width: 425px) {
    .pages-title {
    padding: 5px 0;
  }
    .form-control::placeholder{
      font-size: 95%;
    }
    .table-overflow-x .container .row{
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap ;
      gap: 7px 0;
    }
} 
  @media (min-width: 300px) and (max-width: 375px) {
    .pages-title {
    padding: 2px 0;
  }
    .form-control::placeholder{
      font-size: 85%;
    }
    .form-control{
      width: 90%;
    }
    .table-overflow-x .container .row{
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap ;
      gap: 7px 0;
    }
} 
</style>

<template>
     <ul class="list-group">
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a :href="regulationLinkTwo">
              {{ $t("regulations_page.one") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a :href="regulationLink">
              {{ $t("regulations_page.2024-2025-order-rental") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1bRZlcWrqkhNWO2vS51-JSRP7iy3r2F_3/view?usp=sharing"
            >
              {{ $t("regulations_page.2024-2025-order") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://docs.google.com/document/d/17Eze63sJC2ZDaoJfGd3eMpCF1QEEXoGh/edit?usp=sharing&ouid=117476319524205940709&rtpof=true&sd=true"
            >
              {{ $t("regulations_page.2024-2025") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1L2h_enEtqMfLr0rc5A5RNVHihJSlaydX/view"
            >
              {{
                $t(
                  "regulations_page.the_procedure_for_renting_textbooks_in_educational_organizations"
                )
              }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1vNXMAak8-erGsJi_IDWEAYxkn6I7H_S1/view?usp=sharing"
            >
              {{ $t("regulations_page.rent") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1IRJX_vhdtel0LKGU3pGZb5P6n-gNArsE/view"
            >
              {{
                $t("regulations_page.rental_order_for_2022/2023_academic_year")
              }}
            </a>
          </li>

          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1n2D2cFJBlxa9JUO9-61SbgrnAwkZpUYa/view"
            >
              {{
                $t(
                  "regulations_page.order_of_the_MES_No_1003/1_dated_august_2_2018"
                )
              }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/17KchKd9vOZctgCfjHtvovrGGgIYX7nxI/view"
            >
              {{
                $t(
                  "regulations_page.guidelines_for_renting_textbooks_in_educational_institutions"
                )
              }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a href="#">
              {{
                $t(
                  "regulations_page.implementation_schedule_for_textbook_rental"
                )
              }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1OBirC_1qq5bpbAgY8_0ETAb5d8MA6MuJ/view"
            >
              {{
                $t(
                  "regulations_page.methodology_for_calculating_the_rent_for_the_use_of_textbooks"
                )
              }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1GGNqBy0R_7CBfvpX-AjoDQKMjKejjRYt/view"
            >
              {{ $t("regulations_page.list_of_textbooks") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1h50rHki1cgP8QI8OjZcrrRfqxCcR2JbA/view"
            >
              {{
                $t("regulations_page.order_of_no_1445/1_dated_november_23_2018")
              }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1ahFNHD9wkCM6KaAWf3qDy5guwnDyPLyl/view"
            >
              {{ $t("regulations_page.order_No_1476/1_dated_December_3_2018") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1g87aIxB2Dez8CXgYP5iJQgEzFYCj54OC/view"
            >
              {{ $t("regulations_page.order_1553/1_of_December_17_2018") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/11W-geDl1khAmtEwe8MnDWJECDGgGT9MT/view"
            >
              {{ $t("regulations_page.order_25/1_dated_January_17_2019") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1TqH-czHZe1h7bn9mrmGJwKkMbOZXgobv/view"
            >
              {{ $t("regulations_page.order_1003/1_dated_August_22_2019") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1ocxTy9f6JWGNP91CqkDMXGpdD_wXbsR_/view"
            >
              {{ $t("regulations_page.order_1049/1_dated_September_5_2019") }}
            </a>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-book-open-reader"></i>
            <a
              href="https://drive.google.com/file/d/1bHiGcv_kWN819xBrMncNBmlYZRMwf6ZK/view"
            >
              {{ $t("regulations_page.textbook_lease_agreement") }}
            </a>
          </li>
        </ul>
</template>

<script>
export default {
  computed: {
    regulationLink() {
      // Проверяем текущий язык
      if (this.$i18n.locale === "ru") {
        return "https://drive.google.com/file/d/1IUpA4YnxeY0UjPEoDOuTTkxmChZt_eBg/view?usp=sharing";
      } else {
        return "https://drive.google.com/file/d/1IEe_Y274xDfqkn2DTV0ph3oI2r0yf79d/view?usp=sharing";
      }
    },
    regulationLinkTwo() {
      // Проверяем текущий язык
      if (this.$i18n.locale === "ru") {
        return "https://drive.google.com/file/d/159sGqbLziUUphtxJ5bv1tvN82D6WGVnA/view";
      } else {
        return "https://drive.google.com/file/d/1KadmSv2ULs69Ph2Cp4Z8LfHTT_cMxdwq/view";
      }
    },
  },
};
</script>